$tabs-border: 1px solid #ddd !default;

*[data-tabs] {

  & > input[type="radio"],
  div {
    display: none;
  }

  > div {
    padding-top: 2em;
    clear: both;
    border-top: $tabs-border;
  }

  label {
    background: #f9f9f9;
    border-radius: .25em .25em 0 0;
    color: #888;
    cursor: pointer;
    display: block;
    float: left;
    font-size: 1em;
    height: 2.5em;
    line-height: 2.5em;
    margin: 0 .25em -1px 0;
    padding: 0 1.5em;
    text-align: center;
    border-bottom: $tabs-border;
  }

  input:checked + label {
    background: #fff;
    color: #444;
    position: relative;
    z-index: 6;
    border: $tabs-border;
    border-bottom: none;
    margin-bottom:-1px;
  }

}

input#tab-1:checked ~ #content-1,
input#tab-2:checked ~ #content-2,
input#tab-3:checked ~ #content-3 {
  display: block;
}
