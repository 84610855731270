#progressbar {
  pointer-events: none;
  user-select: none;
  background: $yellow;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  transition: all 0.4s linear;
  opacity: 0;

  &.done{
    opacity: 0;
  }
}