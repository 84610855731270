#browserupgrade{
  position: absolute;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
  background: orange;
  color: #000;
  padding: 50px;
  text-align: center;
  z-index: 9999999;
}