
.page-loaded {
  #navigation,
  #container {
    transition: $navigation-transition;
  }

  body:not(.page-homepage) #navigation {
    transition: none;
  }
}

@keyframes fadeInTop{
  0%{
    opacity: 0;
    transform: translateY(150px);
  }
  100%{
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeInTopLogo{
  0%{
    //opacity: 0;
    transform: translateX(-50%) translateY(-250px);
  }
  100%{
    //opacity: 1;
    transform: translateX(-50%) translateY(0px);
  }
}