@include media($large-screen-up) {

  .page-homepage {
    #container {
      //padding-top: 80vh;
    }

    #navigation:after {
      text-rendering: geometricPrecision;
      line-height: $navigation-height;
      cursor: pointer;
      padding: 0 $navigation-padding/3;
      left: 0;
      font-size: 3em;
      &:hover {
        color: $navigation-color;
      }
      content: '\2261';
      display: block;
      position: fixed;
      text-align: right;
      padding-right: 1em;
      top: 0;
      right: 0;
      width: 100%;
      transition: $navigation-transition;
      opacity: 0;
      z-index: -1;
    }
  }

  #content {
  }

  #progressbar {
    opacity: 1;
  }

  .content .wrap {
    article {
      @include span-columns(8);
    }
    aside {
      @include span-columns(4);
    }
  }


  .page-contact .content .wrap {
    article {
      padding: 0;
      @include span-columns(6);
    }
    aside {
      @include span-columns(6);
    }

    img {
      width: 100%;
      margin-bottom: 2em;
    }

  }

  .page-network .content .wrap {
    aside {
      padding-top: 4em;
    }

    img {
      width: 100%;
      margin-bottom: 2em;
    }
  }


  // Navigation
  .Wallop-buttonPrevious,
  .Wallop-buttonNext {
    width: 100px;
    //display: block;
  }

  .Wallop-item {
    color: white;

    h2 {
      font-size: em(50px);
      color: white;
      margin-bottom: 0;
    }
    p {
      font-size: em(20px);
    }
  }


  .cards {
    .card {
      @include span-columns(4);
      animation: fadeInTop .5s ease-in-out forwards;
      transform: translateY(150px);
      opacity: 0;

      &:nth-child(1) {
        animation-delay: .2s;
      }
      &:nth-child(2) {
        animation-delay: .4s;
      }
      &:nth-child(3) {
        animation-delay: .6s;
      }

      .card-content{
        min-height: 170px;
      }
    }
  }


  *[data-clickable] {
    //@include transition (all 0.3s ease-in-out);
    transition: transform .3s $base-timing;

    &:hover {
      transform: scale(1.05);
      .card-image{
        background: $dark-blue;

        img {
          transform: translateY(-10px);
        }
      }
    }
  }

  #big-logo {
    transition: none;
  }

  html:not(.sticky-nav) {
    .page-homepage {
      #navigation{
        margin-top: -100px;
        &:after {
          opacity: 1;
        }

        &:hover{
          margin-top: 0px;

          &:after{
            opacity: 0;
          }
        }
      }

      #big-logo {
        opacity: 1;
      }
    }

    .Wallop-buttonPrevious,
    .Wallop-buttonNext {
      display: block;
    }

  }

}