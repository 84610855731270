// Animation source : http://tympanus.net/codrops/2012/03/15/parallax-content-slider-with-css3-and-jquery/
@import "node_modules/wallop/css/wallop";

*[data-slideshow] {
  .loader {
    font-size: 10px;
    position: absolute;
    top: 50vh;
    left: 50vw;
    margin-top: -5em;
    margin-left: -5em;
    text-indent: -9999em;
    border-top: 6px solid rgba(255, 255, 255, 0.2);
    border-right: 6px solid rgba(255, 255, 255, 0.2);
    border-bottom: 6px solid rgba(255, 255, 255, 0.2);
    border-left: 6px solid #ffffff;
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
    opacity: 1;
    transition: opacity .4s ease-in-out;
  }
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
}

// Navigation
.Wallop-buttonPrevious,
.Wallop-buttonNext {
  background: url('../../images/right-arrow-white.svg') no-repeat 50% 50%;
  background-size: 50%;
  width: 100px;
  position: absolute;
  top: 0;
  height: 100%;
  cursor: pointer;
  opacity: 0.2;
  transition: opacity .4s ease-in-out;
}
.Wallop-buttonNext {
  right: 0;
}
.Wallop-buttonPrevious {
  transform: rotate(180deg);
}
.Wallop:hover {
  .Wallop-buttonPrevious,
  .Wallop-buttonNext {
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }
  }
}


.Wallop-item {
  width: 100%;
  height: 100vh;
  visibility: visible;

  .wrap {
    //z-index: 1;
  }

  h2,
  p, {
    transform: translateX(110vw);
  }

  .bg {
    background-size: cover;
    background-position: 50% 50%;
    opacity: 0;
    width: 100vw;
    height: 100vh;
  }

  h2 {
    margin-top: 40vh
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: opacity .4s ease-in-out .6s;
  }
}


.assets-loaded{
  *[data-slideshow] {
    .loader{
      opacity: 0;
    }
  }

  .Wallop-item--current {
    h2,
    p,
    .bg{
      opacity: 1;
      transform: translateX(0vw);
    }
  }


  // Slide in from the right
  .Wallop-item--current,
  .Wallop-item--showNext {
    h2 {
      animation: fromRightAnim 0.6s ease-in-out 0.8s both;
    }
    p {
      animation: fromRightAnim 0.6s ease-in-out 0.9s both;
    }
  }

  // Slide in from the left
  .Wallop-item--showPrevious {
    h2 {
      animation: fromLeftAnim 0.6s ease-in-out 0.6s both;
    }
    p {
      animation: fromLeftAnim 0.6s ease-in-out 0.8s both;
    }
  }

  // Slide out to the right
  .Wallop-item--hideNext {
    h2 {
      animation: toRightAnim 0.6s ease-in-out 0.6s both;
    }
    p {
      animation: toRightAnim 0.6s ease-in-out 0.3s both;
    }
  }

  // Slide out to the left
  .Wallop-item--hidePrevious {
    h2 {
      animation: toLeftAnim 0.6s ease-in-out both;
    }
    p {
      animation: toLeftAnim 0.6s ease-in-out 0.3s both;
    }
  }

  .Wallop-item--hideNext,
  .Wallop-item--hidePrevious {
    .bg {
      left: 0;
    }
  }
}

@keyframes toLeftAnim{
  0%{ transform: translateX(0vw);  opacity: 1; }
  30%{ transform: translateX(5vw);  opacity: 1; }
  100%{ transform: translateX(-50vw); opacity: 0; }
}

@keyframes toRightAnim{
  0%{ transform: translateX(0vw);  opacity: 1; }
  100%{ transform: translateX(100vw); opacity: 0; }
}

@keyframes fromRightAnim{
  0%{ transform: translateX(110vw); opacity: 0; }
  100%{ transform: translateX(0vw); opacity: 1; }
}

@keyframes fromLeftAnim{
  0%{ transform: translateX(-110vw); opacity: 0; }
  100%{ transform: translateX(0vw); opacity: 1; }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}