ul,
ol {
  list-style-type: disc;
  margin: 0 0 $small-spacing;
  padding: 0 0 0 em(40px);

  &.inline {
    list-style-type: none;
    padding: 0;

    li {
      display: inline;
      margin-right: 2em;
    }
  }
}

dl {
  margin-bottom: $small-spacing;

  dt {
    font-weight: 600;
    margin-top: $small-spacing;
  }

  dd {
    margin: 0;
  }
}