#languages {
  margin: 0 0 $navigation-padding 0;
  padding: 0;

  legend,
  label {
    display: block;
    margin: 0;
    width: 100%;
    padding: $navigation-padding/2 $navigation-padding/2;
    border-bottom: $navigation-border;
    font-weight: normal;
  }

  legend{
    padding-left: $navigation-padding/2;
    & > i {
      display: none;
    }
  }

  label {
    color: $navigation-color;
    padding-left: $navigation-padding * 2;
    cursor: pointer;

    &:last-child {
      border-bottom: 0;
    }
  }

  .label{
    margin-left: $navigation-padding/2;
  }

  input[type="radio"] {
    display: none;
    &:checked ~ span {
      font-weight: bold;
    }
  }
}


@include media($large-screen-up) {
  #languages {
    float: right;
    display: inline-block;

    label{
      border-bottom: $navigation-border;
      padding: $navigation-padding/2 $navigation-padding/2;
      margin: 0;
      background: $navigation-background;
    }

    .label,
    label{
      display: none;
    }

    fieldset{
      position: relative;

      legend {
        display: inline;
        line-height: $navigation-height;
        padding: 0 $navigation-padding/2;
        margin: 0;
        border: 0;

        & > i {
          display: inline-block;
        }
      }

      &:hover {
        label {
          display: block;
          &.current {
            display: none;
          }
        }
      }
    }
  }
}
