body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: $base-font-size;
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

h1{
  font-weight: normal;
  font-size: $h1-font-size;
}
h2 {
  //line-height: 22px;
}

h1,
h2,
.heading {
  font-weight: 800;
  font-style: normal;
  color: #333333;
  opacity: 1;
  //visibility: visible;
  position: relative;
  width: 100%;
  display: block;
  padding-top: 12px;
  text-transform: uppercase;

  &::after {
    background-color: $yellow;
    position: absolute;
    display: block;
    content: "";
    width: 40px;
    height: 3px;
    left: 0;
    top: 0;
  }
}

.center {
  text-align: center;

  h2::after {
    left: 50%;
    margin-left: -20px;
  }
}

h1, p.highlight, button {
  font-family: $base-font-family;
}

.fonts-loaded{
  h1, h2, h3, p.highlight, button, .heading {
    font-family: $heading-font-family;
  }

  body {
    font-family: 'Open Sans', sans-serif;
  }
}

p {
  margin: 0 0 $small-spacing;
}

a {
  color: $dark-gray;
  //text-decoration: none;
  transition: color $base-duration $base-timing;

  &:active,
  &:focus,
  &:hover {
    color: shade($action-color, 25%);
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}


.name {
  color: $medium-gray;;
  display: inline-block;
  font-size: .75em;
}

.nowrap{
  white-space: nowrap;
}

input[type="submit"],
.btn{
  @extend button;
  background: $dark-gray;

}

.highlight{
  font-size: $h3-font-size;
  font-family: $heading-font-family;
  line-height: $heading-line-height;
  margin-bottom: $base-spacing;
  display: block;
  //text-rendering: optimizeLegibility; // Fix the character spacing for headings
}


.center{
  text-align: center;
}

.slide {
  .heading {
    font-size: em(72px);
    line-height: 1.1;
    color: #fff;
  }

  p {
    color: #fff;
    font-size: em(18px);
  }
}

#cover {
  h1 {
    color : #fff;
    margin: 80px 0 0 0;
  }
  .subtitle {
    color : #fff;
    font-size: em(24px);
  }
}

.Wallop-item {
  color: white;

  h2 {
    font-size: em(36px);
    color: white;
    margin-bottom: 0;
  }
  p {
    font-size: em(18px);
  }
}