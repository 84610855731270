// Typography
$base-font-family: $helvetica;
$heading-font-family: 'Raleway', sans-serif;

// Font Sizes
$base-font-size: 1em;

$h1-font-size: $base-font-size * 3;
$h2-font-size: $base-font-size * 2;
$h3-font-size: $base-font-size * 1.75;
$h4-font-size: $base-font-size * 1.5;
$h5-font-size: $base-font-size * 1.25;
$h6-font-size: $base-font-size;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$blue: #1565c0;
$dark-blue: #1B2936;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;
$gray: #bbb;
$yellow: #fac312;

// Font Colors
$base-font-color: $dark-gray;
$action-color: $blue;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 500ms;
$base-timing: ease-in-out;
$base-delay: 0.2s;
