$navigation-padding: 1em;
$navigation-background: #fff;
$navigation-color: transparentize($dark-gray, 0.3);
$navigation-border: 1px solid darken($navigation-background, 5);
$navigation-height: 70px;
$navigation-transition: all .5s ease-in-out;

//$navigation-background: rgba(0, 0, 0, 0.5);
$navigation-background: rgba(27, 41, 54, 0.8);
$navigation-color: #fff;
$navigation-border: 1px solid rgba(255, 255, 255, 0.1);

#navigation {
  background: $navigation-background;
  color: $navigation-color;
  position: fixed;
  width: 100%;
  z-index: 9999;
  transition: margin .5s ease-in-out;

  .wrap {
    @include outer-container();
    padding: 0;
    text-align: center;
  }

  .logo {
    display: inline-block;
    margin: 5px auto;

    &,
    img {
      max-height: $navigation-height;
    }
  }

  nav {
    text-align: left;
    overflow-y: auto;
    transition: $navigation-transition;
    height: auto;
    max-height: 0;
    zoom: 1;
  }

  ul {
    list-style-type: none;
    margin: 0 0 $navigation-padding 0;
    padding: 0;

    li {
      border-bottom: $navigation-border;

      & > * {
        display: block;
        color: $navigation-color;
        text-decoration: none;
        padding: $navigation-padding/2 $navigation-padding;
        transition: $navigation-transition;
        cursor: pointer;
      }

      /*
      & > a,
      & > span{
        &::after {
          margin-left: $navigation-padding/2;
          content: '\25BE';
          color: $navigation-color;
          opacity: .3;
        }

        &:only-child:after{
          content: '';
        }

        &:hover{
          background: darken($navigation-background, 5);
        }
      }
      */
      /*
      span:hover,
      a:hover{
        background: darken($navigation-background, 5);
      }
      */

      & > span {
        border-bottom: $navigation-border;
      }
      &:last-child {
        border-bottom: 0;
      }

      ul {
        padding: 0;
        margin: 0;
      }

      li a{
        padding-left: $navigation-padding * 2;
      }
    }
  }

  .current {
    background: darken($navigation-background, 5);
  }
}

.nav-expand #navigation{
  nav{
    overflow: scroll;
    max-height: 100vh;
    background: rgba(27, 41, 54, 1);
  }
}


#menu-btn {
  text-rendering: geometricPrecision;
  line-height: $navigation-height;
  display: inline-block;
  cursor: pointer;
  padding: 0 $navigation-padding/3;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 3em;

  &:hover {
    color: $navigation-color;
  }
}


@include media($large-screen-up) {
  #menu-btn {
    display: none;
  }

  #navigation {
    //background: none;
    max-height: $navigation-height;
    //box-shadow: none;

    .wrap {
      max-height: $navigation-height;
      text-align: left;
      padding: 0 $navigation-padding;
    }

    .logo {
      text-align: left;
    }

    nav {
      float: right;
      overflow: visible;
      height: 100%;
      max-height: none;
      zoom: 1;

      ul {
        display: inline-block;
        margin: 0;

        li {
          //background: $navigation-background;
          position: relative;
          display: inline-block;
          vertical-align: top;
          line-height: $navigation-height;
          border: 0;

          & > * {
            display: inline-block;
            padding: 0 14px;
            margin: 0;
            border: 0;
          }

          ul {
            display: none;
            //position: absolute;
            //background: $navigation-background;
            //left: $navigation-padding;
            //top: 3em;
            padding: 0;

            li {
              display: block;
              line-height: 1;

              & > * {
                display: block;
                border-bottom: $navigation-border;
                padding: $navigation-padding;
                margin: 0;
              }

            }
          }


          &:hover {
            ul {
              display: block;
            }
          }
        }
      }
    }
  }
}
