@import "node_modules/normalize.css/normalize";
@import "node_modules/bourbon/app/assets/stylesheets/bourbon";
@import "node_modules/bourbon-neat/app/assets/stylesheets/neat-helpers";
@import "base/grid-settings";
@import "node_modules/bourbon-neat/app/assets/stylesheets/neat";
@import "base/base";

//@import "node_modules/animatewithsass/animate.scss";

@import "components/progressbar";
//@import "components/preloader";
@import "components/navigation";
//@import "components/languages-inline";
@import "components/languages";
@import "components/slideshow";
@import "components/flag";
@import "components/browserupgrade";
@import "components/tabs";
@import "components/news";
@import "animation";

html,
body {
  min-width: 320px;
  min-height: 100vh;
  position: relative;
  background: $dark-blue;
}

section.content {
  background: #fff;
}

.page-homepage {
  #navigation{
    background: rgba(17, 33, 40, 0.8);
  }

  #container {
    position: relative;
    padding-top: 100vh;
  }

  section {
    position: relative;
    z-index: 10;
  }

  #slides {
    position: fixed;
    //position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100vw;
    height: 110vh;
    //transition: $navigation-transition;
    //opacity: .3;

    &:after {
      content: '';
      @include background(linear-gradient(to bottom, rgba(0,0,0,.9) 0%,rgba(0,0,0,.2) 40%,rgba(0,0,0,.2) 60%,rgba(0,0,0,.8) 100%));
      position: absolute;
      height: 110vh;
      width: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }

    .Wallop-item,
    .bg{
      height: 110vh;
    }
  }

  #news a,
  #network a {
    color: #fff;
  }
}

.Wallop-buttonPrevious,
.Wallop-buttonNext {
  width: 50px;
  opacity: 0;
  display: none;
}

/*
.Wallop:hover {
  .Wallop-buttonPrevious,
  .Wallop-buttonNext {
    opacity: 0;
    &:hover {
      opacity: 0;
    }
  }
}
*/
.Wallop-buttonPrevious {
  background-position: 50% 55%;
}

.Wallop-buttonNext {
  background-position: 50% 45%;
}

.Wallop-item {
  .wrap {
    transition: $navigation-transition;
    opacity: 0;
  }

  h2,
  p {
    text-shadow: 1px 1px 5px rgba(0,0,0,.5);
  }

  p {
    font-size: em(20px);
    line-height: 1.2;
  }

  .bg{
    //height: 110vh;
  }
}


#services {
  //background: $dark-blue;
  //margin-bottom: 3em;
  margin-top: -144px;
}

#news,
#network {
  background: none;
  color: darken(#fff, 25%);

  h2 {
    font-size: em(28px);
    color: #fff;
  }
}

.news-wrapper{
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}


.news {
  $base-border-radius: 3px !default;
  $base-spacing: 1.5em !default;
  $action-color: #477DCA !default;
  $news-gutter: 1.4em;
  $news-image-padding: 0.1em;
  $news-image-width: 60px;
  $news-color: $base-font-color;
  $news-background: lighten($action-color, 15%);
  $news-detail-color: transparentize($news-color, 0.5);
  $news-image-vert-alignment: top;

  border-bottom: 1px solid transparentize($news-color, 0.9);
  display: table;
  margin-bottom: $base-spacing;
  padding-bottom: 1em;
  width: 100%;
  text-decoration: none;

  .news-image,
  .news-content {
    display: table-cell;
    vertical-align: $news-image-vert-alignment;
  }

  .news-image {
    padding-right: $news-gutter;

    > img {
      //background: $news-background;
      //border-radius: $base-border-radius;
      display: block;
      height: auto;
      max-width: none;
      padding: $news-image-padding;
      width: $news-image-width;
    }

  }

  .news-content {
    width: 100%;

    .title {
      color: white;
      font-size: 1.2em;
      font-weight: bold;
      margin: 0 0 0.5em 0;
    }

    p {
      line-height: 1.5em;
      margin-bottom: 0.5em;
    }

    p.news-detail {
      color: $medium-gray;
      font-size: 0.9em;
      font-style: italic;
    }
  }
}

#cover {
  background: $dark-blue url('../../images/fotolia_61161215.jpg');
  background-position: 0% 54%;
  background-size: cover;
  height: 300px;
  display: flex;
  align-items: center;
  line-height: 1.2;
  //justify-content: center;
  overflow: hidden;
  //clip-path: polygon(0 0, 100% 0, 100% 75%, 0% 100%);
  &:after {
    content: '';
    @include background(linear-gradient(to bottom, rgba(17, 33, 40,0.4) 0%,rgba(17, 33, 40,.8) 100%));
    position: absolute;
    height: 300px;
    width: 100%;
    top: 0;
    left: 0;
    //z-index: -1;
  }

  & > * {
    z-index: 1;
  }

  .wrap {
    width: 100%;
    z-index: 2;
  }

  h1,
  span.subtitle {
    text-shadow: 1px 1px 5px rgba(0,0,0,.5);
  }
}

.page-road #cover {
  background: url('../../images/fotolia_68738956.jpg');
  background-position: 0% 62%;
  background-size: cover;
}
.page-storage #cover {
  background: url('../../images/fotolia_71360088.jpg');
  background-position: 0% 61%;
  background-size: cover;
}
.page-containers #cover {
  background: url('../../images/fotolia_88362719.jpg');
  background-position: 0% -300%;
}
.page-contact #cover {
  background: url("../../images/mohahug-v7.jpg");
  background-position: 100% 0%;
  background-size: cover;
}

.page-network {
  article {
    img {
      width: 100%;
    }
  }
  .heading{
    font-size: 1em;
    line-height: 1.4;
  }
  #cover {
    background: url('../../images/worldmap.jpg');
    background-position: 0% -125%;
    background-size: cover;
  }
}

.page-overseas article {
  a {
    color: #1565C0;
  }
}


.wrap {
  @include outer-container();
  padding: 2em 1em;
  position: relative;

  article {
    padding: 2em 0;
  }
  aside {
    position: relative;
    p:first-child{
      img {
        width: 100%;
      }
    }

    .fixed {
      position: fixed;
    }
  }
}


.cards {
  @include outer-container();
  padding: 1em;

  .card {
    margin-bottom: 2em;

    a {
      display: block;
      text-decoration: none;
    }

    .card-image {
      overflow: hidden;
      height: 135px;
      max-height: 135px;
      text-align: center;

      img {
        @include transition (all 0.3s ease-in-out);
        opacity: 1;
        padding-top: 1em;
      }
    }

    .card-content {
      background: #fff;
      padding: 20px;
    }
  }
}

table{
  margin-bottom: 4em;

  tr:nth-child(even) {
    background-color: #f6f6f6;
  }
}


footer {
  text-align: center;
  color: darken(#fff, 25%);

  img {
    margin-bottom: 1em;
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    color: #fff;

    small {
      color: darken(#fff, 25%);
    }
  }
}


#big-logo {
  display: block;
  position: fixed;
  left: 50%;
  text-align: center;
  opacity: 0;
  z-index: 1;
  top: 25vh;
  transform: translateX(-50%);
  //animation: fadeInBottom .8s ease-out forwards;
  transition: all .8s $base-timing .1s;
  animation: fadeInTopLogo .8s ease-out forwards;


  img {
    min-width: 280px;
  }
}
/*
#navigation {
  //data-100="background: rgba(27, 41, 54, 0.8); box-shadow: 0px 6px 10px 0px rgba(0,0,0,0);"
  background: rgba(27, 41, 54, 1);
  box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.4);
  transition: $navigation-transition;
}
*/

html:not(.sticky-nav) {

  .Wallop-buttonPrevious,
  .Wallop-buttonNext {
    opacity: 0;
  }

  .Wallop-item .wrap{
    opacity: 1;
  }

  .page-homepage {
    #navigation{
      margin-top: -100px;
    }

    #container {
      //padding-top: 80vh;
    }
  }

  /*
  #navigation {
    background: rgba(27, 41, 54, 0.8);
    box-shadow: 0px 6px 10px 0px rgba(0,0,0,0);
  }
  */


  #big-logo {
    top: 5vh;
    opacity: 1;
  }

}


*[data-clickable] {
  cursor: pointer;
  position: relative;
}


*[data-tabs] {
  padding-top: 2em;

  h2 {
    font-size: 1.8em;
    margin-bottom: 1em;
  }
  h3 {
    //font-family: $base-font-family;
    text-transform: uppercase;
    font-size: 1.1em;
    //font-weight: normal;
    //background: $yellow;
    //color: white;
    //padding: 2px 10px;
    border-bottom: 1px solid $yellow;
    padding: 2px 0;
  }
}


fieldset{
  margin-bottom: 50px;
  legend{
    margin-bottom: 25px;
  }
}
.form-group{
  margin-bottom: 18px;

  input {
    margin-bottom: 0;
  }
}
.radio-inline{
  display: inline-block;
  font-weight: normal;
  margin-right: 15px;
}

.help-block {
  font-size: 12px;
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
  color: #a94442;
}
.has-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}
.alert {
  padding: 15px;
  margin-bottom: 60px;
  border: 1px solid transparent;
  border-radius: 4px;
}

#navigation .quote{
  //background: rgba(249, 194, 17, 0.67);
  background: hsla(198, 95%, 52%, 0.67);
}

#regions_div {
  margin-bottom: 2em;

}
/*
#languages {
  display: none;
}
*/

@import "responsive";
